import React from 'react';
import { Link } from 'react-router-dom';

export default function ErrorPage (){
    return(
        <div style={{textAlign: "center"}}>
            <h1>Page not Found</h1>
            <Link to="/">Go Back?</Link>
        </div>
    );
}