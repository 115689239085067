import React from 'react';
import '../App.css';
import 'animate.css';
import RandomNumbers from './RandomNumbers';
import Anagram from './Anagram';
import { Link} from 'react-router-dom';

function PlayGround(){
    const displayRandomNumbers = () => {
        document.querySelector(".modal").style.display = "flex";
        document.querySelector(".playground_content").style.visibility = 'hidden';
    }
    const displayAnagram = () => {
        document.querySelector(".anagram").style.display = "flex";
        document.querySelector(".modal").style.display = "none";
        document.querySelector(".playground_content").style.visibility = 'hidden';
    }
        return(
        <div className="playground">
            <div className="nav">
                <Link to="/" className="back"><img src="https://res.cloudinary.com/benjee/image/upload/v1597409271/toys/back_arrow_gzsxy6.svg" alt="" width="20" height="20" />Home</Link>
                <Link to="/gallery" className="back">gallery<img src="https://res.cloudinary.com/benjee/image/upload/v1597409271/toys/front_arrow_azpzsu.svg" alt="" width="20" height="20" /></Link>
            </div>
            <div className="playground_content">
                <div className="playground-header">
                    <h1>Welcome to Guy G's Playground</h1>
                    <p>Play fun games <br/>Click play to begin a game</p>
                </div>
                <div  className="playground-body">
                    <div className="card grow">
                        <img src="https://res.cloudinary.com/benjee/image/upload/v1597409271/toys/three_wsvvvn.svg" alt="" />
                        <h2>Guess the Number</h2>
                        <p onClick={displayRandomNumbers}>play</p>
                    </div>
                    <div className="card grow">
                        <img src="https://res.cloudinary.com/benjee/image/upload/v1597409270/toys/question_if3y2g.svg" alt="" />
                        <h2>Anagram</h2>
                        <p  onClick={displayAnagram}>play</p>
                    </div>
                </div>
            </div>  
            <RandomNumbers /> 
            <Anagram />
        </div> 
    )
}

export default PlayGround;