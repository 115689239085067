import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ErrorPage from './Component/ErrorPage';

import PlayGround from './Component/PlayGround';
import Gallery from './Component/ImageGallery';
import LandingPage from './Component/LandingPage';

function App() {

  return (
    
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/playground" component={PlayGround} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;