import React from 'react';
import '../App.css';
import 'animate.css';
import { Link} from 'react-router-dom';
import Gallery from 'react-grid-gallery';
import IMAGES from '../util/images';
function ImageGallery(){
    return(
        <div className="slider-wrapper">
          <div className="nav">
              <Link to="/" className="back"><img src="https://res.cloudinary.com/benjee/image/upload/v1597409271/toys/back_arrow_gzsxy6.svg" alt="" width="20" height="20" />Home</Link>
              <Link to="/playground" className="back">playground<img src="https://res.cloudinary.com/benjee/image/upload/v1597409271/toys/front_arrow_azpzsu.svg" alt="" width="20" height="20" /></Link>
          </div>
          <div className="gallery_showcase">
            <Gallery images={IMAGES} id="imgg" />
          </div>
        </div>
    )
}

export default ImageGallery;