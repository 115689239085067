const IMAGES =
[
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-2.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-2.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-3.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-3.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-4.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-4.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-5.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-5.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-6.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-6.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-7.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-7.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-8.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-8.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-9.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-9.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-10.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-10.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-11.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-11.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-12.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-12.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-13.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-13.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-14.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-14.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-15.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-15.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-16.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-16.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-17.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-17.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-18.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-18.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-19.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-19.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-20.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-20.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-21.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-21.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-22.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-22.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-23.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-23.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-24.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-24.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-25.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-25.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-26.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-26.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-27.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-27.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-28.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-28.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-29.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-29.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-30.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-30.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-31.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-31.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-32.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-32.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-33.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-33.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-34.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-34.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-35.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-35.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-36.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-36.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-37.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-37.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-38.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-38.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-39.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-39.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-40.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-40.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-41.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-41.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-42.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-42.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-88.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-88.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-83.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-83.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-45.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-45.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-46.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-46.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-47.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-47.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-48.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-48.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-49.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-49.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-50.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-50.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-51.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-51.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-52.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-52.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-53.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-53.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-54.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-54.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-55.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-55.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-56.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-56.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-57.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-57.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-58.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-58.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-59.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-59.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-60.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-60.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-61.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-61.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-62.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-62.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-63.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-63.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-64.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-64.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-83.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-83.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-66.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-66.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
},
{
src: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-67.jpg",
thumbnail: "https://res.cloudinary.com/benjee/image/upload/v1597309260/guy-g/guy-g-67.jpg",
thumbnailWidth: 50,
thumbnailHeight: 70
}
]
export default IMAGES;

