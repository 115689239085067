import React from 'react';
import 'animate.css';
import { Link } from 'react-router-dom';
import '../App.css';


function LandingPage(){
    
    return(
        <div  className="container animate__animated animate__animated animate__fadeIn">
           
            <div>
                <Link to='/gallery' >
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409062/toys/spiderman_1_digzcn.svg" height="62" width="62" id="spiderman" alt=""/>
                </Link>
                <Link to='/gallery' >
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409060/toys/groot_1_snvk8t.svg" height="62" width="62" id="groot" alt=""/>
                </Link>
                <Link to='/playground'>
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409060/toys/iron-man_1_i1pjs3.svg" height="62" width="62" id="ironman" alt=""/>
                </Link>
                <Link to='/gallery'>
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409060/toys/marvel_1_iqa1md.svg" height="62" width="62" id="marvel" alt=""/>
                </Link>
                <Link to='/gallery'>
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409060/toys/superman_1_sfpmar.svg" height="62" width="62" id="superman" alt=""/>
                </Link>
                
                
            </div>
            <div className="welcome_text">
                <h1>Hi! I'm Guy G</h1>
                <p>Click on my toys to know about me</p>
            </div>
            <div>
                <Link to='/playground'>
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409062/toys/superman_1_1_iquvty.svg" height="62" width="62" id="supermanlogo" alt=""/>
                </Link>
                <Link to='/gallery'>
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409059/toys/thor_1_ublncg.svg" height="62" width="62" id="thor" alt=""/>
                </Link>
                <Link to='/gallery'>
                    <img src="https://res.cloudinary.com/benjee/image/upload/v1597409060/toys/ant-man_1_xtb0qv.svg" height="62" width="62" id="antman" alt=""/>
                </Link>
            </div>
            {/*<div className="landingpage-content">
                <div className="landingpage-text">
                    <h1>
                        Hi! <br /> 
                        I'm Guy G <br />
                        Welcome to my page 
                    </h1>
                </div>
                <div className="menu">
                    <div className="menuitem grow">
                        <Link to='/gallery'>
                            <span>s</span>
                            <h3>My Gallery</h3>
                        </Link>
                    </div>
                    <div className="menuitem grow">
                        <Link to='/playground'>
                            <span>s</span>
                            <h3>My Playground</h3>
                        </Link>
                    </div>
                </div>
        </div>*/}
        </div> 
    )
}

export default LandingPage;