import React, {useState, useEffect} from 'react';
import anagramQuestions from '../util/anagramQuestions.json';

// const [questions, setQuestions] = useState([]);

// useEffect(()=> {
//     fetch(anagramQuestions).then(data => {
//         return data.json();
//     })
//     .then(data => {
//         setQuestions(data)
//     })
// }, []);

// console.log(questions)

// const fetchList = fetch('https://jsonplaceholder.typicode.com/posts')
// .then(data =>{
//     return data.json();
// }).then(data => {
//     console.log(data);
// })
// console.log(anagramQuestions);

export default function Anagram(){
    let rand = Math.floor(Math.random() * 20 ) +1;
    
    const [items, setItems] = useState([]);

    const fetchItems = () => {
        let ques = setItems(anagramQuestions[rand].question);
        if(ques === undefined){
            ques = "loading questions";
            return ques;
        }
        else{
            return ques;
        }
        
    }
    useEffect(() => {
       fetchItems();
    }, []);

    const userinput = () => {
        const input = document.getElementById("userinput").value;
        const isUserCorrect = anagramQuestions.find(({ answer }) => answer === input);
        if(isUserCorrect === undefined){
            document.getElementById("message").innerHTML = `Opps Incorrect answer 😥 Try Again <br /> <b>${items}</b>`;
        }

        else if(isUserCorrect.answer){
            document.getElementById("message").innerHTML = "correct 😃. Want to try again?";
        }
        else{
            document.getElementById("message").innerHTML = `Opps Incorrect answer 😥. Try Again ${<br />} ${<b>${items}</b>}`;
        }
    }
   const keyPress = (e) => {
        if(e.KeyCode == 13 || e.which == 13){
            userinput();
        }
   }

    
    const exitModal = (e) =>{
        window.location.reload(true);
        document.querySelector(".anagram").style.display = "none";
        document.querySelector(".playground_content").style.visibility = 'visible';
    }
    return(
        <div className="anagram">
                <div className="modal-header">
                    <h1>Anagram</h1>
                    <span data-dismis="anagram" onClick={exitModal}>x</span>
                </div>
                <div className="message-box">
                    <p id="message">What is the anagram of <b>{items}</b> </p>
                </div>
                <div className="modal-body">
                    <div className="user-input">
                        <input onKeyPress={keyPress} type="text" id="userinput"/>
                        <button className="btn" onClick={userinput}>check</button>
                    </div>
                </div>
            </div>
    )
}