import React from 'react';

export default function RandomNumbers(){
    let answer = Math.floor(Math.random() * 5 ) +1; 
    
    const firstNumber = () => {
        return Math.floor(Math.random() * 4 ) + 1;
    }
    const secondNumber = () => {
        return Math.floor(Math.random() * 5 ) +1;
    }
    const thirdNumber = () => {
        return Math.floor(Math.random() * 3 ) +1;
    }
    const checkAnswer = () => {
        if(firstNumber() === answer || secondNumber() === answer || thirdNumber() === answer){
            document.querySelector(".modal-body").style.display = "none";
            document.getElementById("success").style.display = "block";
            document.querySelector(".message").style.display = "none";
        }
        else{
            document.querySelector(".modal-body").style.display = "none";
            document.querySelector(".message").style.display = "none";
            document.getElementById("tryagain").style.display = "block";        
        }
    }
    const tryagain = ()=> {
        window.location.reload(true);
    }
    const exitModal = (e) =>{
        window.location.reload(true);
    }
    return(
        <div className="modal">
                <div className="modal-header">
                    <h2>Guess the Number</h2>
                    <span data-dismis="modal" onClick={exitModal}>x</span>
                </div>
                <div className="message-box">
                    <p className="message">Guess the number i'm thinking! pick from one of the three numbers</p>
                    <p id="success">yay!!🎉🎉🎉 You guessed correct 😃 <br /> <b onClick={tryagain}>Try again</b></p>
                    <p id="tryagain">Opps Incorrect selection 😥 <br /> <b onClick={tryagain}>Try again</b></p>
                </div>
                <div className="modal-body">
                    <div onClick={checkAnswer} className="first-number">
                        <span>{firstNumber()}</span>
                    </div>
                    <div onClick={checkAnswer} className="second-number">
                        <span>{secondNumber()}</span>
                    </div>
                    <div onClick={checkAnswer} className="third-number">
                        <span>{thirdNumber()}</span>
                    </div>
                </div>
            </div>
    )
}